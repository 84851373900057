import { ButtonLink } from '@/elements/Link/Buttons'
import { Button } from '@shipt/design-system-buttons'
import { Column } from '@shipt/design-system-layouts'
import { screenSizes } from '@shipt/design-system-themes'
import { Body, Headline } from '@shipt/design-system-typography'
import { useEffect } from 'react'
import styled, { css } from 'styled-components'

type Props = {
  isBestValue: boolean
  callout: string
  name: string
  isDiscounted: boolean
  originalPrice: number
  price: number
  buttonCta: string
  onButtonClick: () => void
  interval: string
  description: string
  href?: string
  buttonAriaLabel?: string
  trackViewed?: () => void
  isCarousel?: boolean
}

export const PlanCardV2 = ({
  isBestValue,
  callout,
  name,
  isDiscounted,
  originalPrice,
  price,
  interval,
  description,
  buttonCta,
  onButtonClick,
  href,
  buttonAriaLabel,
  trackViewed,
  isCarousel = false,
}: Props) => {
  useEffect(() => {
    trackViewed?.()
  }, [trackViewed])

  return (
    <CardContainer isBestValue={isBestValue} key={name} isCarousel={isCarousel}>
      {isBestValue && (
        <BestValueCallout>
          <Body strong size="md">
            {callout}
          </Body>
        </BestValueCallout>
      )}
      <CardTextContainer spacing="lg" justify="space-between">
        <div>
          <Body size="xl">{name} plan</Body>
          {isDiscounted ? (
            <Headline size="xl" as="h2">
              <OldPrice>${originalPrice}</OldPrice>{' '}
              <PromoPrice>${price}</PromoPrice>/first {interval}
            </Headline>
          ) : (
            <Headline size="xl" as="h2">
              ${price}/{interval}
            </Headline>
          )}

          <Body variant="secondary" size="md">
            {description}
          </Body>
        </div>
        {href ? (
          <ButtonLink
            onClick={onButtonClick}
            size="md"
            aria-describedby="bold_text"
            aria-label={buttonAriaLabel}
            href={href}
            variant={isBestValue ? 'primary' : 'secondary'}
          >
            {buttonCta}
          </ButtonLink>
        ) : (
          <Button
            concept="action"
            size="md"
            variant={isBestValue ? 'primary' : 'secondary'}
            onClick={onButtonClick}
            aria-label={`${buttonCta} ${name}`}
          >
            {buttonCta}
          </Button>
        )}
      </CardTextContainer>
    </CardContainer>
  )
}

const CardContainer = styled.div<{
  isBestValue: boolean
  isCarousel: boolean
}>`
  background-color: ${({ theme }) =>
    theme.color.background.base.neutral.subtle_00.value};
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: ${({ isBestValue, theme }) =>
    isBestValue ? `0 0 0 0.25rem ${theme.green}` : `${theme.shadows[1]}`};
  width: 100%;
  position: relative;

  @media ${screenSizes.max_mobile} {
    ${({ isCarousel }) =>
      isCarousel &&
      css`
        margin: 1rem 0 1.5rem;
        width: 17.5rem;
      `}
  }

  @media ${screenSizes.tablet} {
    width: calc(50% - 0.5rem);
  }
`

const CardTextContainer = styled(Column)`
  height: 100%;
`

const BestValueCallout = styled.div`
  top: -0.75rem;
  left: 1.5rem;
  padding: 0 0.5rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) =>
    theme.color.background.action.secondary_loud.value};
  position: absolute;
`

const OldPrice = styled.span`
  text-decoration: line-through;
  color: ${({ theme }) => theme.color.content.base.neutral.secondary.value};
  font-weight: 300;
`

const PromoPrice = styled.span`
  color: ${({ theme }) => theme.color.content.feedback.promotion.primary.value};
`

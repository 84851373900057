import { useState } from 'react'

export const useHandleZoomImage = (zoom = true) => {
  const [isZooming, setIsZooming] = useState(false)

  const handlePanZoomClick: React.ComponentProps<'div'>['onMouseMove'] = (
    e
  ) => {
    setIsZooming(!isZooming)
    setZoomOrigin(e)
  }

  const handlePanZoomMove: React.ComponentProps<'div'>['onClick'] = (e) => {
    if (!zoom || !isZooming) return
    setZoomOrigin(e)
  }

  const setZoomOrigin = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const el = e.currentTarget
    const rect = el.getBoundingClientRect()
    el.style['transformOrigin'] = `${
      ((e.clientX - rect.left) / rect.width) * 100
    }% ${((e.clientY - rect.top) / rect.height) * 100}%`
  }

  return {
    handlePanZoomClick,
    handlePanZoomMove,
    isZooming,
  }
}

import styled, { css } from 'styled-components'
import { useState } from 'react'
import { Navigation, Thumbs, FreeMode } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Image } from '@/elements/Image/NextImage'
import { shadow1, shadow2 } from '@/styles/effects'
import { type Swiper as SwiperType, type SwiperOptions } from 'swiper/types'
import { useHandleZoomImage } from '@/hooks/useHandleZoomImage'
import { trackElementClicked } from '@/analytics/element'

type Props = Pick<SwiperOptions, 'slidesPerView' | 'threshold'> & {
  slideImages?: string[]
  imageOpacity: string
  zoom: boolean
  productName: string
  productSize: string
}

export const ThumbsCarousel = ({
  slideImages = [],
  imageOpacity = '',
  productName = '',
  productSize = '',
  slidesPerView = 4,
  zoom = false,
  threshold = 0,
}: Props) => {
  const { handlePanZoomClick, handlePanZoomMove, isZooming } =
    useHandleZoomImage(zoom)
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null)

  return (
    <Wrapper>
      <Prev className="swiper-button-prev" />
      <Next className="swiper-button-next" />
      <SwiperContainerThumbs
        className="swiper-container-thumbs"
        modules={[Navigation, Thumbs, FreeMode]}
        onSwiper={setThumbsSwiper}
        slidesPerView={slidesPerView}
        spaceBetween={16}
        watchOverflow={true}
        watchSlidesProgress={true}
        direction="vertical"
        freeMode={true}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        threshold={threshold}
      >
        {slideImages.map((url, i) => (
          <SwiperSlide key={i}>
            <ImageButton
              aria-label={`slide ${i + 1} of ${slideImages.length}`}
              onClick={() =>
                trackElementClicked({
                  content: 'image_carousel',
                })
              }
            >
              <ThumbsProductImageWrapper>
                <ThumbsProductImage
                  src={url}
                  alt={`slide ${i + 1} of ${
                    slideImages.length
                  }, ${productName}, ${productSize}`}
                  $imageOpacity={imageOpacity}
                  fill
                  style={{ objectFit: 'contain' }}
                  unoptimized
                />
              </ThumbsProductImageWrapper>
            </ImageButton>
          </SwiperSlide>
        ))}
      </SwiperContainerThumbs>
      <MainProductImagesWrapper
        modules={[Navigation, Thumbs, FreeMode]}
        simulateTouch={false}
        speed={0}
        thumbs={{ swiper: thumbsSwiper }}
      >
        {slideImages.map((url, i) => {
          const alt = `slide ${i + 1} of ${
            slideImages.length
          }, ${productName}, ${productSize}`
          const priority = i === 0
          return (
            <SwiperSlide key={url}>
              <MainImageWrapper
                onClick={handlePanZoomClick}
                onMouseMove={handlePanZoomMove}
                className={`${zoom && 'swiper-zoom-container'} ${
                  isZooming && 'is-zooming'
                }`}
                $isZooming={isZooming}
              >
                {isZooming ? (
                  <MainProductImage
                    src={url}
                    alt={alt}
                    $imageOpacity={imageOpacity}
                    height={300}
                    width={300}
                    unoptimized
                  />
                ) : (
                  <MainProductImage
                    src={url}
                    alt={alt}
                    $imageOpacity={imageOpacity}
                    style={{ objectFit: 'contain' }}
                    fill
                    priority={priority}
                    unoptimized
                  />
                )}
              </MainImageWrapper>
            </SwiperSlide>
          )
        })}
      </MainProductImagesWrapper>
    </Wrapper>
  )
}

const Prev = styled.div`
  top: 0;
  left: 25px;
  color: black;

  &::after {
    transform: rotate(90deg);
    font-size: 1rem;
  }
`
const Next = styled.div`
  top: 350px;
  left: 25px;
  color: black;

  &::after {
    transform: rotate(90deg);
    font-size: 1rem;
  }
`

const Wrapper = styled.div`
  display: flex;
  margin: 1.5rem 0;
`

const SwiperContainerThumbs = styled(Swiper)`
  padding: 0.5rem;
  width: 20%;
  height: 300px;

  .swiper-slide-thumb-active > button {
    ${shadow2};
  }

  .swiper-slide > button:hover {
    ${shadow2};
  }
`

const ImageButton = styled.button`
  ${shadow1};
  border: none;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: transparent;
  cursor: pointer;
  width: 60px;
  height: 60px;
  position: relative;
`

const ThumbsProductImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const ThumbsProductImage = styled(Image<true>)<{
  $imageOpacity: string
}>`
  opacity: ${({ $imageOpacity }) => $imageOpacity};
`

const MainProductImagesWrapper = styled(Swiper)`
  width: 80%;
  max-height: 300px;
`

const MainImageWrapper = styled.div<{ $isZooming: boolean }>`
  ${({ $isZooming }) =>
    !$isZooming &&
    css`
      position: relative;
      height: 300px;
    `}
`

const MainProductImage = styled(Image<true>)<{
  $imageOpacity: string
}>`
  opacity: ${({ $imageOpacity }) => $imageOpacity};
`
